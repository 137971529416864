.menu-section {
    height: 100%;
    padding: 100px 0 0px 0;

    .menu-item {
        width: 100%;
        padding: 10px;
        display: block;
        cursor: pointer;
        position: relative;
        text-align: center;
        text-decoration: none;
        background-color: #fff;
        .menu-item-img {
            height: 145px;
            overflow: hidden;
            position: relative;
            border-radius: 10px;

            &:after {
                content: "";
                inset: 0;
                z-index: 1;
                position: absolute;
                background-color: #0000005c;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s linear;
                -webkit-transition: all 0.3s linear;
            }
        }
        .menu-item-name {
            color: #000000;
            font-size: 16px;
            margin-top: 5px;
            font-weight: 700;
            line-height: 19px;
        }

        &:hover {
            .menu-item-img {
                img {
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                }
            }
        }
    }
}


@media only screen and (max-width: 480px) {
    .menu-section {
        height: auto;
        padding-bottom: 20px;
        .menu-item {
            .menu-item-img {
                height: 102px;
                margin: 0 auto;
                border-radius: 5px;
            }

            .menu-item-name {
                font-size: 14px;
                -webkit-line-clamp:inherit
            }
        }
    }
}