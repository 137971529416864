.categories-wrapper {
    background-color: #f7f7f7;

    .side-nav-tabs {
        height: 75vh;
        overflow-y: auto;
        background-color: #fff;
        border-right: 1px solid #eeeeee;

        .dropdown {
            top: 0;
            z-index: 2;
            position: sticky;
            background-color: #fff;

            .dropdown-toggle {
                color: #000;
                width: 100%;
                border: 0;
                height: 65px;
                font-size: 16px;
                text-align: left;
                border-radius: 0;
                padding: 0 20px;
                font-weight: 600;
                background-color: transparent;

                &::after {
                    color: #222;
                    top: 50%;
                    right: 10px;
                    position: absolute;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                }
            }

            .dropdown-menu {
                padding: 0;
                width: 100%;
                border-radius: 0;
                box-shadow: 0 5px 15px #0000001a;

                .dropdown-item {
                    font-size: 14px;
                    padding: 8px 20px;

                    &:hover {
                        background-color: #ba261814;
                    }
                }
            }
        }

        .nav-pills {
            border: 0;
            display: block;
            background-color: #fff;

            .nav-item {
                .nav-link {
                    color: #1f1f1f;
                    display: block;
                    border: 0;
                    width: 100%;
                    height: 65px;
                    font-size: 14px;
                    padding: 0 20px;
                    line-height: 65px;
                    text-align: left;
                    border-radius: 0;
                    position: relative;
                    border-top: 1px solid #eeeeee;

                    &.active {
                        color: #dc3545;
                        font-weight: 600;
                        background-color: transparent;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 4px;
                            height: 100%;
                            background-color: #dc3545;
                        }
                    }
                }
            }
        }
    }

    .category-tabs-contents {
        .category-title {
            padding: 20px;
            position: sticky;
            top: 0;
            z-index: 99;
            display: flex;
            height: 65px;
            align-items: center;
            background-color: #fff;
            justify-content: space-between;
            border-bottom: 1px solid #eeeeee;

            h4 {
                font-size: 16px;
            }

            .choose-filter {
                input[type='radio'] {
                    display: none;

                    &:checked+label {
                        color: #dc3545;
                        border-color: #dc3545;
                        position: relative;
                    }
                }

                label {
                    cursor: pointer;
                    padding: 4px 20px;
                    border-radius: 25px;
                    text-align: center;
                    font-size: 12px;
                    margin-right: 10px;
                    transition: .3s;
                    font-weight: 500;
                    border: solid 1px #dee2e6;
                }
            }
        }

        .tab-content {
            .tabs-content-wrapper {
                height: 68vh;
                overflow-y: auto;
            }

            .item-dish-list {
                min-height: 80px;
                padding: 12px;
                max-height: 120px;
                border-radius: 4px;
                margin-bottom: 12px;
                background: #fff;
                border: 1px solid #e5e5e5;
                box-shadow: 0 3px 5px #00000017;

                .item-dish-left {
                    display: flex;
                    cursor: pointer;
                    align-items: center;

                    .dish-img {
                        width: 78px;
                        height: 75px;
                        margin-right: 10px;
                        background-color: #ccc;
                    }

                    .content {
                        .dish-time-status {
                            display: flex;
                            align-items: center;

                            .time {
                                color: #423f3f;
                                font-size: 12px;

                                i {
                                    font-size: 12px;
                                }
                            }

                            .pepper-hot {
                                margin-left: 5px;

                                i {
                                    font-size: 12px;
                                }
                            }
                        }

                        h3 {
                            color: #000;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        p {
                            color: #8b8b8b;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }

                .item-dish-right {
                    .form-select {
                        width: 200px;
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .categories-wrapper {
        .side-nav-tabs {
            height: auto;
            border-right: 0;
            position: relative;
            overflow-y: visible;
            background-color: #ffffff;

            .items-tabs-header {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                margin-top: 10px;

                .nav-pills {
                    .nav-item {
                        .nav-link {
                            height: 55px;
                            font-size: 12px;
                            line-height: 55px;
                            white-space: nowrap;

                            &.active {
                                background-color: #FFCFCF;

                                &::before {
                                    top: auto;
                                    bottom: 0;
                                    width: 100%;
                                    height: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .category-tabs-contents {
            .category-title {
                height: auto;
                border-bottom: 0;
                background-color: transparent;

                .choose-filter-wrapper {
                    display: none !important;
                }

                .dropdown {
                    .dropdown-toggle {
                        font-size: 14px;
                        color: #BD3225;
                        border-color: #BD3225;
                        background-color: transparent;
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        box-shadow: 0 5px 15px #0000001a;

                        .dropdown-item {
                            font-size: 14px;
                            padding: 8px 20px;

                            &:hover {
                                background-color: #ba261814;
                            }
                        }
                    }
                }

            }

            .tab-content {
                .tabs-content-wrapper {
                    height: auto;
                    padding: 0 10px;
                }
                .item-dish-list {
                    .item-dish-left {
                        .dish-img {
                            width: 60px;
                            height: 55px;
                        }

                        .content {
                            h3 {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }

                    .item-dish-right {
                        .form-select {
                            width: 100%;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}