.empty-state {
    text-align: center;
    margin-top: 10rem;

    img {
        width: 100px;
    }

    .empty-state-title {
        color: #424242;
        font-size: 16px;
        margin-top: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 767px) {
    .empty-state {
        margin-top: 5rem;
    }
}