.customer-details-page {
    .customer-header {
        display: flex;
        padding: 15px 10px;
        align-items: center;
        background-color: #ffffff;
        justify-content: space-between;
        h5 {
            color: #191919;
            font-size: 14px;
            margin-bottom: 2px;
        }

        h6 {
            color: #877878;
            font-size: 12px;
        }

        a {
            i {
                font-size: 18px;
            }
            color: #000000;
            text-decoration: none;
        }
    }

    .customer-details {
        padding: 10px 0;

        .customer-name {
            padding: 0 10px;
            h3 {
                color: #000000;
                font-size: 12px;
            }

            h4 {
                color: #2F2F2F;
                font-size: 14px;
            }
        }

        .customer-table {
            padding: 0 10px;
            table {
                thead {
                    tr {
                        th {
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            color: #191919;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .order-summary {
            background-color: #ffffff;
            table {
                thead {
                    tr {
                        border-bottom: 1px solid #ccc;
                        th {
                            padding: 10px;
                            color: #000;
                            font-size: 14px;
                        }
                    }
                }
                tbody {
                    tr {
                        border-bottom: 1px solid #ccc;
                        &:last-child {
                            border-bottom: 0;
                        }
                        td {
                            padding: 5px 10px;
                            color: #191919;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .payment-summary {
            padding: 10px;
            background-color: #ffffff;
            h2 {
                font-size: 14px;
                color: #191919;
                font-weight: 600;
                margin-bottom: 5px;
            }

            ul {
                li {
                    display: flex;
                    padding:  5px 0;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        font-size: 12px;
                    }

                    b {
                        font-size: 14px;
                    }

                    &.last {
                        border-top: dashed 1px #ccc;
                    }
                }
            }
        }

        .add-tips {
            padding: 10px;
            background-color: #ffffff;
            h2 {
                font-size: 14px;
                color: #191919;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .tips-bttn {
                display: flex;
                button {
                    color: #8D8D8D;
                    font-size: 12px;
                    padding: 5px 25px;
                    border-radius: 25px;
                    margin-right: 10px;
                    border-color: #BBBBBB;
                    background-color: transparent;
                }
            }
        }

        .pay-now-bttn {
            left: 0;
            right: 0;
            bottom: 0;
            padding: 10px;
            position: static;
            background-color: #ffffff;
           
            button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span,
                h5 {
                    font-size: 14px;
                }

                h4 {
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .customer-details-page {
        .customer-header {
            border-top: 1px solid #ccc;
        }
        
        .customer-details {
            .pay-now-bttn {
                position: absolute;
                box-shadow: 0px -4px 15px #1010101a;
            }
        }
    }
}