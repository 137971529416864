html,
body,
#root {
  height: 100%;
}

body {
  background-color: #f9f9f9 !important;
  font-family: "Inter", sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  p {
    margin: 0;
    padding: 0;
  }


  ul {
    li {
      list-style: none;
    }
  }

  .web-view {
    display: block;
  }

  .mobile-view {
    display: none;
  }

  // FORM FIELD
  .form-control,
  .form-select {
    font-size: 14px;

    &:focus {
      box-shadow: none;
      outline: none;
      border-color: #222;
    }
  }


  // ONE TWO THREE ELLIPSIS
  .one-line-ellipsis,
  .two-line-ellipsis,
  .three-line-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    &.one-line-ellipsis {
      -webkit-line-clamp: 1;
    }

    &.two-line-ellipsis {
      -webkit-line-clamp: 2;
    }

    &.three-line-ellipsis {
      -webkit-line-clamp: 3;
    }
  }

  // PEPPER HOT 
  .pepper-hot {
    i {
      color: #ba2f2d;
    }
  }

  // SUB HEADER
  .sub-header {
    ul {
      display: flex;
      align-items: center;

      li {
        margin-right: 8px;

        a {
          color: #777;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          text-decoration: none;

          &:hover {
            color: #dc3545;
          }
        }

        span {
          color: #222;
          font-weight: 600;
          font-size: 16px;
          line-height: 14px;
        }
      }
    }
  }

  // CARD
  .card {
    &.card-one {
      height: 100%;
      border: 0;
      border-radius: 6px;
      box-shadow: 0 5px 15px #0000001a;
    }

    .card-header {
      background-color: #ffffff;

      .page-header-title {
        font-size: 18px;
      }

      .page-header-sub-title {
        font-size: 14px;
      }

    }
  }

  // DISH INDICATOR
  .dish-indicator {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #cccccc;

    &::after {
      content: "";
      left: 50%;
      top: 50%;
      width: 5px;
      height: 5px;
      height: 50%;
      position: absolute;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }

    &.non-veg {
      border-color: #D24444;

      &::after {
        background-color: #d24444;
      }
    }

    &.veg {
      border-color: #20c67c;

      &::after {
        background-color: #20c67c;
      }
    }
  }

  // PAGE SECTION
  .page-section {
    height: 100%;
    padding: 80px 0 0px 0;

    .sub-header {
      position: sticky;
      top: 88px;
      padding: 20px;
      display: flex;
      z-index: 99;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fff;

      .category-search-input {
        position: relative;

        i {
          top: 50%;
          left: 10px;
          position: absolute;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
        }

        input {
          padding-left: 30px;
        }
      }
    }
  }
}


@media screen and (max-width: 991px) {
  body {
    .container {
      max-width: 100%;
    }

  }
}

@media screen and (max-width: 767px) {
  body {
    .sm-p-0 {
      padding: 0;
    }

    .sm-pr-0 {
      padding-right: 0;
    }

    .sm-pl-0 {
      padding-right: 0;
    }

    .sub-header {
      ul {
        li {

          a,
          span {
            font-size: 12px;
          }
        }
      }
    }

    .web-view {
      display: none;
    }

    .mobile-view {
      display: block;
    }

    .page-section {
      padding-top: 60px;
      .sub-header {
          position: static;
          padding: 10px;
          border-top: 1px solid #ccc;
      }
  }
  }
}

@media screen and (max-width: 480px) {
  body {
    .container-sm-p0 {
      padding: 0;
    }
    .card {
      .card-header {
        .page-header-title {
          font-size: 16px;
        }

        .page-header-sub-title {
          font-size: 12px;
        }
      }
    }

    .card.card-one {
      box-shadow: none;
    }
  }
}