.page-header {
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px #0000001a;
    .navbar-toggler {
        &:focus,
        &:active {
            box-shadow: none;
            outline: none;
        }
    }
}

@media screen and (max-width: 991px) {
    .page-header {
        height: 60px;
        &.fixed-top {
            box-shadow: none;
        }

        .navbar-brand {
            margin-left: 10px;
            img {
                width: 120px;
            }
        }

        .navbar-toggler {
            margin-right: 10px;
        }

        .custom-container {
            padding: 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .page-header {
        .header-menu {
            padding: 10px;
            box-shadow: -1px 4px 15px #1010101a;
        }
    }
}